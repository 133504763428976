import React, { useEffect, useRef } from "react";
import { ChatState } from "../context/AllProviders";
import MessageCard from "./Messagecard/MessageCard";

const Message = ({ chatData, chatLoading }) => {
  const messagesEndRef = useRef(null);
  const { setIsViewerOpen, setSelectedImage, scrolarinmiddle } = ChatState();
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView();
  };

  useEffect(() => {
    if (scrolarinmiddle === false) {
      setTimeout(() => {
        scrollToBottom();
      }, 200);
    }
  }, [chatData, scrolarinmiddle]);

  const locationData = (inputString) => {
    const parts = inputString.split(",");
    const latitude = parts[0];
    const longitude = parts[1];
    return {
      latitude: latitude,
      longitude: longitude,
    };
  };

  const openpdf = (data) => {
    const url = data;
    window.open(url, "_blank");
  };

  const openImageViewer = (imageUrl) => {
    setIsViewerOpen(true);
    setSelectedImage(imageUrl);
  };

  return (
    <>
      {scrolarinmiddle === true && (
        <button
          className="btn btn-success"
          style={{
            // zIndex: "10",
            position: "absolute",
            bottom: "20%",
            right: "5%",
            padding: "0",
            backgroundColor: "rgba(var(--bs-primary-rgb), 1)"
          }}
          onClick={scrollToBottom}
        >
          <i className="bx bx-chevron-down" style={{ fontSize: "3em" }} />
        </button>
      )}
      {chatLoading && <li className="d-flex justify-content-center" style={{ marginTop: '60px' }}>
        <div className="spinner-border text-success" role="status">
          <span className="sr-only"></span>
        </div>
      </li>}


      {chatData.map((message, index) => (
        <li key={index} className={`d-flex ${message.req_from === "USER" ? "justify-content-start" : "justify-content-end"} align-items-center`}>
          {message.req_from === "USER" ? (
            <div className="" style={{ marginLeft: "1%" }}>
              {message.message_type === "TEXT" && (
                <MessageCard
                  position={"left"}
                  type={"text"}
                  text={message.message_content}
                  date={message.created}
                  status={message.status}
                  user={message.req_from}

                />
              )}
              {message.message_type === "IMAGE" && (
                <MessageCard
                  position={"left"}
                  type={"photo"}
                  text={message.image_caption}
                  date={message.created}
                  data={{
                    uri: message.file_url,
                  }}
                  onClick={() => openImageViewer(message.file_url)}
                />
              )}

              {message.message_type === "VIDEO" && (
                <MessageCard
                  position={"left"}
                  type={"video"}
                  text={message.image_caption}
                  date={message.created}
                  data={{
                    videoURL: message.file_url,
                    status: {
                      click: true,
                      loading: 0.5,
                      download: true,
                    },
                  }}
                />
              )}
              {message.message_type === "LOCATION" && (
                <MessageCard
                  position={"left"}
                  type={"location"}
                  date={message.created}
                  href={`https://www.google.com/maps/place/${message.message_content}`}
                  src="https://cdn.pixabay.com/photo/2016/03/22/04/23/map-1272165_1280.png"
                  data={locationData(message.message_content)}
                />
              )}

              {message.message_type === "DOCUMENT" && (
                <MessageCard
                  position={"left"}
                  type={"file"}
                  onClick={() => openpdf(message.file_url)}
                  text="Document"
                  data={{
                    uri: message.file_url,
                    status: {
                      click: false,
                      loading: 0,
                    },
                  }}
                  // className="doc-width"
                  date={message.created}
                />
              )}
              {message.message_type === "AUDIO" && (
                <MessageCard
                  position={"left"}
                  type={"audio"}
                  text={message.image_caption}
                  data={{
                    audioURL: message.file_url,
                  }}
                  date={message.created}
                />
              )}
              {message.message_type === "FLOW" && (() => {
                let messageContent;
                // Check if message_content is valid JSON
                try {
                  messageContent = JSON.parse(message.message_content);
                  delete messageContent.flow_token;
                } catch (e) {
                  console.error("Invalid JSON:", e);
                  return null; // Stop rendering if message_content is invalid
                }


                return (<MessageCard
                  position={"left"}
                  type={"form"}
                  text={message.image_caption}
                  data={{
                    content: messageContent,
                  }}
                  date={message.created}
                />)
              }
              )()}
            </div>
          ) : (
            <div style={{ marginRight: "1%" }}>
              {message.message_type === "FLOW" && (() => {
                let messageContent;
                // Check if message_content is valid JSON
                try {
                  messageContent = JSON.parse(message.message_content);
                  
                } catch (e) {
                  console.error("Invalid JSON:", e);
                  return null; // Stop rendering if message_content is invalid
                }


                return (<MessageCard
                  position={"right"}
                  type={"form"}
                  text={message.image_caption}
                  data={{
                    content: messageContent,
                  }}
                  date={message.created}
                  user={message.req_from}
                />)
              }
              )()}
              {message.message_type === "TEXT" && (
                <MessageCard
                  position={"right"}
                  type={"text"}
                  text={message.message_content}
                  date={message.created}
                  status={message.status === "pending" ? "sent" : "received"}
                  user={message.agent_name}
                />
              )}
              {message.message_type === "IMAGE" && (
                <MessageCard
                  position={"right"}
                  type={"photo"}
                  text={message.image_caption}
                  data={{
                    uri: message.file_url,
                  }}
                  date={message.created}
                  onClick={() => openImageViewer(message.file_url)}
                />
              )}
              {message.message_type === "VIDEO" && (
                <MessageCard
                  position={"right"}
                  type={"video"}
                  text={message.image_caption}
                  data={{
                    videoURL: message.file_url,
                    status: {
                      click: true,
                      loading: 0.5,
                      download: true,
                    },
                  }}
                  date={message.created}
                />
              )}
              {message.message_type === "LOCATION" && (
                <MessageCard
                  position={"right"}
                  type={"location"}
                  href={`https://www.google.com/maps/place/${message.message_content}`}
                  src="https://cdn.pixabay.com/photo/2016/03/22/04/23/map-1272165_1280.png"
                  data={locationData(message.message_content)}
                  date={message.created}
                />
              )}

              {message.message_type === "document" && (
                <MessageCard
                  position={"right"}
                  type={"file"}
                  text="Document"
                  onClick={() => openpdf(message.file_url)}
                  data={{
                    uri: message.file_url,
                    status: {
                      click: false,
                      loading: 0,
                    },
                  }}
                  date={message.created}
                />
              )}
              {message.message_type === "AUDIO" && (
                <MessageCard
                  position={"right"}
                  type={"audio"}
                  text={message.image_caption}
                  status={message.status === "pending" ? "sent" : "received"}
                  fileUrl={message.file_url}
                  date={message.created}
                />
              )}

              {message.message_type === "MENU" && (() => {
                let messageContent;
                // Check if message_content is valid JSON
                try {
                  messageContent = JSON.parse(message.message_content);
                } catch (e) {
                  console.error("Invalid JSON:", e);
                  return null; // Stop rendering if message_content is invalid
                }

                return (
                  <MessageCard
                    position={"right"}
                    type={"list"}
                    text={messageContent?.body?.text}
                    list={messageContent?.action?.sections[0]?.rows}
                    date={message.created}
                    button={messageContent?.action?.button}
                    status={message.status === "pending" ? "sent" : "received"}
                    user={message.req_from}
                  />
                );
              })()}


            </div>
          )}
        </li>
      ))}
      {/* Empty div to scroll to */}
      <div ref={messagesEndRef} />
    </>
  );
};

export default Message;
