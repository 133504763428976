import React from "react";
import { LineChart, Line, BarChart, Bar, PieChart, Pie, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";

// ChartSection Component with Chart.js Integration
const ChartSection = ({ title, data, type }) => {
    const renderChart = () => {
        switch (type) {
            case "line":
                return (
                    <LineChart data={data}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="Date" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="Total Chats" stroke="#FF9F40" />
                        <Line type="monotone" dataKey="Read Chats" stroke="#36A2EB" />
                        <Line type="monotone" dataKey="Unread Chats" stroke="#FF6384" />
                        <Line type="monotone" dataKey="New Chats" stroke="#4BC0C0" />
                        <Line type="monotone" dataKey="Repeated Chats" stroke="#FFCD56" />
                    </LineChart>
                );
            case "bar":
                return (
                    <BarChart data={data}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="Date" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="value" fill="#8884d8" barSize={40} />
                    </BarChart>
                );
            case "pie":
                return (
                    <PieChart width={400} height={400}>
                        <Pie
                            data={data}
                            dataKey="value"
                            nameKey="Date"
                            cx="50%"
                            cy="50%"
                            outerRadius={100}
                            fill="#8884d8"
                            label
                        >
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.color} />
                            ))}
                        </Pie>
                        <Tooltip />
                        <Legend />
                    </PieChart>
                );
            default:
                return null;
        }
    };

    return (
        <div className="p-3 w-100" style={{marginRight:"3rem"}}>
           {type!=='pie'&& <h2 className="text-xl font-semibold text-gray-700 mb-4">{title}</h2>}
            <ResponsiveContainer width="100%" height={300}>
                {renderChart()}
            </ResponsiveContainer>
        </div>
    );
};


export default ChartSection;