import React, { createContext, useContext, useEffect, useState } from "react";
import { getCookie } from "../utils/Utils";
import { io } from "socket.io-client";
import { SOCKET_URL,BASE_URL } from "../api/api";
import axios from "axios";
import { AuthContext } from "./AuthContext";
import { ChatContext } from "./ChatContext";
import { deleteCookie } from "../utils/Utils";
const AllContext = createContext();

const AllProvider = ({ children }) => {
  // const navigate= useNavigate();
  const [loading, setLoading] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const { dispatch } = useContext(ChatContext);
  const [text, setText] = useState("");
  const [socket, setSocket] = useState(null);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [unReadChat, setUnReadChat] = useState([]);
  const [selectedMobileNumber, setSelectedMobileNumber] = useState(null);
  const [selectedUserDetails,setSelectedUserDetails]=useState({
    email:"",
    company:""
  })
  const [chats, setChats] = useState([]);
  const [convpage, setConvPage] = useState(0);
  const [scrolarinmiddle, setScrolarinmiddle] = useState(false);
  const [page, setPage] = useState(0);
  const [selectedBtn, setSelectedBtn] = useState("all");
  const [allChats, setAllChats] = useState([]);
  const [starChats, setStarChats] = useState([]);
  const [waitingChats,setWaitingChats]=useState([]);
  const [filteredChats,setFilteredChats]=useState([]);
  const [checkboxList, setCheckboxList] = useState([]);
  const [chatsLoading, setChatsLoading] = useState(false);
  const [selectedName, setSelectedName] = useState("");
  const [isOldMsg, setIsOldMsg] = useState(false);
  const [sendTemplatePopUp, setSendTemplatePopUp] = useState(false);
  const [wpProfile, setWpProfile] = useState([]);
  const [callData, setCallData] = useState({
    visible: false,
    name: "",
    mobile: null,
    content: "",
    message_type: "",
  });

  useEffect(() => {
    const userCookie = getCookie("user");
    const userInfo = userCookie ? JSON.parse(userCookie) : null;
    const currentuser = userInfo?.data;
    const socketconn = io(SOCKET_URL);
    if (currentuser?.parent_id) {
      socketconn.emit("setup", currentuser);
     
      
      setSocket(socketconn);
      socketconn.on('user_not_found',(data)=>{

        if(currentUser.user_id===data.user_id && currentUser.user_type!=="admin"){
          deleteCookie("user");
        }

      })
    }
    return () => {
      socketconn.off('user_not_found');
      socketconn.disconnect();
    };
  }, []);

  const handleChatOpen = async (item) => {
    setSelectedMobileNumber(item.mobile);
    setSelectedName(item.name);
    setLoading(true);
    setConvPage(0);
    setText("");

    let readdata = {
      ...item,
      brand_number: currentUser.brand_number,
      parent_id: currentUser.parent_id,
    };

    const givenDate = new Date(item.created);
    const currentDate = new Date();
    const time_23hrs_59min_ago = new Date(
      currentDate.getTime() - (23 * 60 * 60 * 1000 + 59 * 60 * 1000)
    );
    if (givenDate < time_23hrs_59min_ago) {
      setIsOldMsg(true);
    } else {
      setIsOldMsg(false);
    }
    try {
      const forconvdata = {
        token: currentUser.parent_token,
        user_id: currentUser.parent_id,
        method: "conv_list_new",
        brand_number: currentUser.brand_number,
        start: 0,
        from_mobile: item.mobile,
      };

      const res = await axios.post(
        `${BASE_URL}/netcore_conversation.php`,
        forconvdata
      );

      if (res.data.success === true) {
        // socket.emit("read", readdata);
        let updatedunreadchat = unReadChat.filter(
          (items, index) => items.read_status === 0
        );

        setUnReadChat(updatedunreadchat);

        const index = chats.findIndex(
          (selecteditem) => selecteditem.mobile === readdata.mobile
        );

        const unreadindex = updatedunreadchat.findIndex(
          (selecteditem) => selecteditem.mobile === readdata.mobile
        );

        if (unreadindex > -1) {
          const updatedUnreadItems = [...updatedunreadchat];
          updatedUnreadItems[index] = {
            ...updatedUnreadItems[index],

            read_status: 1,
          };

          updatedUnreadItems.sort(
            (a, b) => a.id - b.id
          );

          setUnReadChat(updatedUnreadItems);
        }

        if (index !== -1) {
          const updatedItems = [...chats];
          updatedItems[index] = {
            ...updatedItems[index],

            read_status: 1,
          };

          updatedItems.sort(
            (a, b) => new Date(b.created) - new Date(a.created)
          );

          setChats(updatedItems);
        }

        if (selectedBtn === "all") {
          setUnReadChat((prevItems) =>
            prevItems.filter((items, index) => items.mobile !== readdata.mobile)
          );
        }

        let updatedconv = res.data.data;
        updatedconv.sort((a, b) => a.id - b.id);

        await dispatch({
          type: "CHANGE_USER",
          payload: {
            mobile: item.mobile,
            conversation: updatedconv,
            name: item.name,
          },
        });
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <AllContext.Provider
      value={{
        isViewerOpen,
        setIsViewerOpen,
        selectedImage,
        setSelectedImage,
        unReadChat,
        setUnReadChat,
        selectedMobileNumber,
        setSelectedMobileNumber,
        chats,
        setChats,
        convpage,
        setConvPage,
        scrolarinmiddle,
        setScrolarinmiddle,
        page,
        setPage,
        selectedBtn,
        setSelectedBtn,
        allChats,
        setAllChats,
        starChats,
        setStarChats,
        checkboxList,
        setCheckboxList,
        chatsLoading,
        setChatsLoading,
        selectedName,
        setSelectedName,
        isOldMsg,
        setIsOldMsg,
        sendTemplatePopUp,
        setSendTemplatePopUp,
        wpProfile,
        setWpProfile,
        callData,
        setCallData,
        socket,
        text,
        setText,
        handleChatOpen,
        waitingChats,
        setWaitingChats,
        filteredChats,
        setFilteredChats,
        selectedUserDetails,
        setSelectedUserDetails
      }}
    >
      {children}
    </AllContext.Provider>
  );
};

export const ChatState = () => {
  return useContext(AllContext);
};

export default AllProvider;
