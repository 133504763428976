
import Home from "./pages/Home";
import Login from "./pages/Login";
import AgentManagementPage from "./pages/AgentManagementPage";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { AuthContext } from "./context/AuthContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import VerifyAccount from "./components/VerifyAccount";
import CreateAgentPage from "./pages/CreateAgentPage";
import AgentReportPage from "./pages/AgentReportPage";
import Setting from "./pages/Setting";
import axios from "axios";
import { BASE_URL2 } from "./api/api";
function App() {
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    // Remove query parameters on app refresh
    window.history.replaceState(null, '', window.location.pathname);
  }, []);

  //Sync sentences with the DB every hour
  useEffect(() => {
    const syncToDatabase = async () => {

      if (!currentUser?.parent_id) return;
      // Fetch sentences from the database
      const fetchBody = {
        user_id: currentUser.parent_id,
        token: currentUser.parent_token,
        method: "retrieve",
      }
      axios.post(`${BASE_URL2}/suggestion`, fetchBody)
        .then((response) => {
          const sentencesFromDB = response?.data?.data[0]?.suggestion || []; // Handle missing data
          // Fetch sentences from localStorage
          const savedSentences = JSON.parse(localStorage.getItem('sentences')) || [];
          // Merge sentences and filter out duplicates
          const allSentences = [...new Set([...sentencesFromDB, ...savedSentences])];
          // post all sentence back to db
          if (allSentences.length > 0) {
            const body = {
              user_id: currentUser.parent_id,
              token: currentUser.parent_token,
              method: "insert",
              suggestion: allSentences
            };
            axios.post(`${BASE_URL2}/suggestion`, body)
              .then(() => {
                // console.log("Sentences synced successfully with the database.");
              })
              .catch((error) => {
                console.error("Error inserting sentences into the database:", error);
              });

          }
          // Save the merged sentences back to localStorage
          localStorage.setItem('sentences', JSON.stringify(allSentences));

        })
        .catch((error) => {
          console.error('Error syncing sentences with the database:', error);
        })
    };

    // Initial sync when the component mounts
    syncToDatabase();

    // Set up an interval for hourly sync
    const interval = setInterval(syncToDatabase, 3600000); // 1 hour

    // Cleanup interval on unmount
    return () => clearInterval(interval);
  }, [currentUser]);


  const domainName = window.location.hostname;
  const ProtectedRoute = ({ children }) => {
    if (!currentUser) {
      return <Navigate to="/" />;
    }
    return children;
  };
  const AdminRoute = ({ children }) => {
    if (!currentUser || currentUser.user_type === "agent") {
      return <Navigate to="/" />;
    }
    return children;
  };

  useEffect(() => {
    document.title = domainName;
  }, [domainName])

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path="/agent-management/agent"
          element={
            <AdminRoute>
              <AgentManagementPage />
            </AdminRoute>
          }
        />
        <Route
          path="/agent-management/create-agent"
          element={
            <AdminRoute>
              <CreateAgentPage />
            </AdminRoute>
          }
        />
        <Route
          path="/agent-management/report"
          element={
            <AdminRoute>
              <AgentReportPage />
            </AdminRoute>
          }
        />
        <Route
          path="/agent-management/setting"
          element={
            <AdminRoute>
              <Setting />
            </AdminRoute>
          }
        />
        <Route path="/" element={<Login />} />
        <Route path="/verify-account" element={<VerifyAccount />} />
      </Routes>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </BrowserRouter>
  );
}

export default App;
