import React, { useState } from 'react';
import { getContrastingTextColor } from '../../utils/Utils';

const LabelCard = ({ labelData }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <div
      style={{
        display: 'inline-block',
        position: 'relative', // For positioning the tooltip
        margin: '0.5rem',

      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {/* Label */}
      <div
        style={{
          backgroundColor: labelData.color_code || '#28a745',
          color: getContrastingTextColor(labelData.color_code),
          padding: '.5rem 1.2rem',
          borderRadius: '1.2rem',
          cursor: 'pointer',
          width: "fit-content",
          fontSize: "clamp(.8rem, 2vw, .9rem)",
          textAlign: "center"
        }}
      >
        <span>{labelData.name}</span>
      </div>

      {/* Tooltip for description */}
      {isHovered && labelData.description && (
        <div
          style={{
            position: 'absolute',
            top: '100%', // Position below the label
            left: '-20%',
            transform: 'translateX(-50%)',
            backgroundColor: '#000',
            color: '#FFF',
            padding: '0.5rem',
            borderRadius: '4px',
            marginTop: '0.5rem',
            fontSize: '0.875rem',
            // whiteSpace: 'nowrap', // Prevent text wrapping
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            zIndex: 1000,
          }}
        >
          {labelData.description}
        </div>
      )}
    </div>
  );
};

export default LabelCard;
