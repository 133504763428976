import { IoCloseSharp } from "react-icons/io5";
import { CiEdit } from "react-icons/ci";
import { MdDelete } from "react-icons/md";
import style from './quickReply.module.css';
import { useEffect, useState, useContext } from "react";
import { ChatState } from "../../context/AllProviders";
import { AuthContext } from "../../context/AuthContext";
import { toast } from "react-toastify";
import axios from "axios";
import { BASE_URL2 } from "../../api/api";
import DeleteModal from "../DeleteModal/DeleteModal";
import { CircularProgress } from '@mui/material';

const QuickReply = ({ setActiveTab, setshowQuickReply }) => {
    const [isAdding, setIsAdding] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentQuickReply, setCurrentQuickReply] = useState({
        title: "",
        text: ""
    });
    const [quickReplies, setQuickReplies] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedReply, setSelectedReply] = useState(null);
    const [editReplyText, setEditReplyText] = useState({
        title: "",
        text: ""
    });
    const [editingReplyId, setEditingReplyId] = useState(null);
    const [filteredReplies, setFilteredReplies] = useState([]); // State for filtered replies
    const [searchQuery, setSearchQuery] = useState("");

    const { selectedMobileNumber, setText } = ChatState();
    const { currentUser } = useContext(AuthContext);


    const handleClose = () => {
        setshowQuickReply(false);
        setActiveTab("");
    };

    const handleSendMessage = (reply) => {
        setText(reply.text);
        setshowQuickReply(false);
        setActiveTab("");

        const body = {
            user_id: currentUser.parent_id,
            token: currentUser.parent_token,
            method: "click_count",
            id: reply._id
        }
        try {
            axios.post(`${BASE_URL2}/quick_reply`, body);

        } catch (error) {
            console.log(error)

        }

    }

    useEffect(() => {
        const fetchQuickReply = async () => {

            const body = {
                user_id: currentUser.parent_id,
                token: currentUser.parent_token,
                method: "retrieve",
                user_type: currentUser.user_type,
                agent_id: currentUser.user_id

            }
            setLoading(true);
            try {
                const response = await axios.post(`${BASE_URL2}/quick_reply`, body);
                if (response.data.success) {
                    setQuickReplies(response.data.data);
                    setFilteredReplies(response.data.data);
                }
            } catch (error) {
                console.error("Error fetching quick replies:", error);
                toast.error("Failed to load quick replies. Please try again.");

            } finally {
                setLoading(false);
            }

        }
        fetchQuickReply();
    }, [currentUser, selectedMobileNumber]);

    // Handle search query input
    const handleSearch = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchQuery(query);

        // Filter replies based on the search query or show all if query is empty
        if (query === "") {
            setFilteredReplies(quickReplies);
        } else {
            const filtered = quickReplies.filter((reply) =>
                reply.text.toLowerCase().includes(query) ||
                reply.title.toLowerCase().includes(query)
            );
            setFilteredReplies(filtered);
        }
    };


    const handleAdd = async () => {

        if (currentQuickReply.text.trim() === "" || currentQuickReply.title.trim() === "") return;
        setIsAdding(true);

        const newReply = {
            text: currentQuickReply.text,
            title: currentQuickReply.title,
            status: currentUser.user_type === "admin" ? 1 : 0,
            agent_id: currentUser.user_type === "admin" ? 0 : currentUser.user_id,
            _id: `temp-${Date.now()}`

        };

        setQuickReplies((prevReplies) => [...prevReplies, newReply]);
        setFilteredReplies((prevReplies) => [...prevReplies, newReply]);
        setCurrentQuickReply({
            title: "",
            text: ""
        }); // Clear input field after successful addition
        const body = {
            user_id: currentUser.parent_id,
            token: currentUser.parent_token,
            method: "create",
            text: currentQuickReply.text,
            agent_id: currentUser.user_type === "admin" ? 0 : currentUser.user_id,
            user_type: currentUser.user_type,
            title: currentQuickReply.title
        };

        try {
            const response = await axios.post(`${BASE_URL2}/quick_reply`, body);
            if (response.data.success) {
                setQuickReplies((prevReplies) =>
                    prevReplies.map((reply) =>
                        reply._id === newReply._id ? { ...reply, _id: response.data.id } : reply
                    )
                )
                setFilteredReplies((prevReplies) =>
                    prevReplies.map((reply) =>
                        reply._id === newReply._id ? { ...reply, _id: response.data.id } : reply
                    )
                );
                toast.success("Reply added successfully");
            } else {
                toast.error("Failed to add the reply. Please try again.");
                setQuickReplies((prevReplies) =>
                    prevReplies.filter((reply) => reply._id !== newReply._id)
                );
                setFilteredReplies((prevReplies) =>
                    prevReplies.filter((reply) => reply._id !== newReply._id)
                );
            }

        } catch (error) {
            toast.error("An error occurred while adding the reply. Please try again.");
            console.error("Error adding notes:", error);
            setQuickReplies((prevReplies) =>
                prevReplies.filter((reply) => reply._id !== newReply._id)
            );
            setFilteredReplies((prevReplies) =>
                prevReplies.filter((reply) => reply._id !== newReply._id)
            );

        } finally {
            setIsAdding(false);
        }

    }

    const handleDeleteClick = (reply, e) => {
        e.stopPropagation();
        setSelectedReply(reply);
        setShowModal(true);
    };

    const handleDeleteConfirm = async () => {
        // Backup the current state to revert if the API fails
        const previousReplies = [...quickReplies];
        const previousFilteredReplies = [...filteredReplies];

        // Optimistically update the UI by removing the selected reply immediately
        const updatedReplies = quickReplies.filter((reply) => reply._id !== selectedReply);
        const updatedFilteredReplies = filteredReplies.filter((reply) => reply._id !== selectedReply);

        setQuickReplies(updatedReplies);
        setFilteredReplies(updatedFilteredReplies);

        setShowModal(false);


        const body = {
            user_id: currentUser.parent_id,
            token: currentUser.parent_token,
            method: "delete",
            id: selectedReply

        };

        try {

            const response = await axios.post(`${BASE_URL2}/quick_reply`, body);
            if (response.data.success) {
                toast.success("Reply deleted successfully")
            } else {
                setQuickReplies(previousReplies);
                setFilteredReplies(previousFilteredReplies);
                toast.error("Failed to delete the reply. Please try again.");

            }

        } catch (error) {

            console.error("Error deleting reply:", error);
            setQuickReplies(previousReplies);
            setFilteredReplies(previousFilteredReplies);
            toast.error("An error occurred while deleting the reply. Please try again.");

        }
        finally {
            setSelectedReply(null);

        }
    };

    const handleCancelEdit = (e) => {
        e.stopPropagation();
        setEditingReplyId(null);
        setEditReplyText({
            title: "",
            text: ""
        }); // Clear edit state
    };

    const handleCancel = () => {
        setShowModal(false);
        setSelectedReply(null);
    };



    const handleEditClick = (reply, e) => {
        e.stopPropagation();
        setEditingReplyId(reply._id);
        setEditReplyText({
            title: reply.title,
            text: reply.text
        }); // Populate the textarea with the current note text
    };

    const handleSaveClick = async (reply, e) => {
        e.stopPropagation();

        const previousReply = { ...reply };
        // Optimistically update UI
        setQuickReplies((prevReplies) =>
            prevReplies.map((r) =>
                r._id === reply._id ? { ...r, text: editReplyText.text, title: editReplyText.title } : r
            )
        );
        setFilteredReplies((prevReplies) =>
            prevReplies.map((r) =>
                r._id === reply._id ? { ...r, text: editReplyText.text, title: editReplyText.title } : r
            )
        );

        const body = {
            user_id: currentUser.parent_id,
            token: currentUser.parent_token,
            method: "update",
            id: reply._id,
            status: reply.status,
            text: editReplyText.text,
            title: editReplyText.title
        };

        try {
            const response = await axios.post(`${BASE_URL2}/quick_reply`, body);
            if (response.data.success) {

                toast.success("Reply updated successfully");
            } else {
                setQuickReplies((prevReplies) =>
                    prevReplies.map((r) =>
                        r._id === reply._id ? previousReply : r
                    )
                );
                setFilteredReplies((prevReplies) =>
                    prevReplies.map((r) =>
                        r._id === reply._id ? previousReply : r
                    )
                );
                toast.error("Failed to update the reply. Please try again.");
            }

        } catch (error) {
            console.error("Error saving reply:", error);
            setQuickReplies((prevReplies) =>
                prevReplies.map((r) =>
                    r._id === reply._id ? previousReply : r
                )
            );
            setFilteredReplies((prevReplies) =>
                prevReplies.map((r) =>
                    r._id === reply._id ? previousReply : r
                )
            );
            toast.error("Failed to update the reply. Please try again.");

        } finally {
            setEditingReplyId(null); // Exit edit mode
        }


    };


    return (
        <div className={`${style.replyCard} shadow`}>
            {/* Header Section */}
            <header className="d-flex justify-content-between align-items-center p-2">
                <h4 className={`${style.title} m-0`}>Quick Replies</h4>
                <IoCloseSharp
                    onClick={handleClose}
                    className={`${style.closeIcon} clickable`}
                />
            </header>

            {/* Search Bar */}
            <div className={`${style.searchBar} p-2`}>
                <input
                    type="text"
                    placeholder="Search here..."
                    className={`${style.searchInput} form-control`}
                    value={searchQuery}
                    onChange={handleSearch}
                />
            </div>

            <div className={style.mainDiv}>


                {/* Replies Container */}
                {loading
                    ? (<div className="text-center py-4">
                        <CircularProgress />
                    </div>)
                    : (
                        <>
                            <div className={`${style.repliesContainer} py-2`}>
                                {/* Public Replies */}
                                <section className={`${style.section}`}>
                                    {/* <h5 className={`${style.sectionTitle}`}>Public Replies</h5> */}
                                    <div className={`${style.replyList}`}>
                                        {filteredReplies
                                            .sort((a, b) => b.click_count - a.click_count)
                                            .map((reply) => (
                                                <div key={reply._id} className={`${editingReplyId === reply._id ? 'flex-column' : 'flex-row'} ${style.reply} p-2`} >
                                                    {editingReplyId === reply._id ? (
                                                        <div className="w-100">
                                                            {/* Title Field for Editing */}
                                                            <input
                                                                type="text"
                                                                className={`${style.titleInput} form-control mb-2`}
                                                                value={editReplyText.title}
                                                                onChange={(e) => {
                                                                    e.stopPropagation();
                                                                    e.preventDefault();
                                                                    setEditReplyText((prev) => ({ ...prev, title: e.target.value }))
                                                                }
                                                                }
                                                                onFocus={(e) => {
                                                                    e.stopPropagation();
                                                                    e.preventDefault();
                                                                }}
                                                                placeholder="Edit Title"
                                                            />
                                                            {/* Text Field for Editing */}
                                                            <textarea
                                                                className={`${style.textarea} form-control`}
                                                                value={editReplyText.text}
                                                                onChange={(e) => {
                                                                    e.stopPropagation();
                                                                    e.preventDefault();
                                                                    setEditReplyText((prev) => ({ ...prev, text: e.target.value }))

                                                                }
                                                                }
                                                                onFocus={(e) => {
                                                                    e.stopPropagation();
                                                                    e.preventDefault();
                                                                }}
                                                                placeholder="Edit Text"
                                                            />
                                                        </div>
                                                    ) : (
                                                        <div className={style.replyContent} onClick={() => handleSendMessage(reply)}>
                                                            <strong className={style.hoverTitle}>{reply.title}:</strong>
                                                            <div className={style.replyText}>{reply.text}</div>
                                                        </div>
                                                    )}

                                                    <div className={`${editingReplyId === reply._id ? 'flex-row' : 'flex-column'} ${style.options}`}>

                                                        {editingReplyId === reply._id ? (
                                                            <>
                                                                <button
                                                                    className="btn btn-success w-100"
                                                                    onClick={(e) => handleSaveClick(reply, e)}
                                                                >
                                                                    Save
                                                                </button>
                                                                <button
                                                                    className="btn btn-secondary w-100"
                                                                    onClick={handleCancelEdit}
                                                                >
                                                                    Cancel
                                                                </button>
                                                            </>

                                                        ) :

                                                            ((reply.agent_id!==0 || currentUser.user_type==="admin") && (
                                                                <>
                                                                    <CiEdit
                                                                        onClick={(e) => handleEditClick(reply, e)}
                                                                        className="clickable mx-1"
                                                                    />
                                                                    <MdDelete
                                                                        onClick={(e) => handleDeleteClick(reply._id, e)}
                                                                        className="clickable mx-1"
                                                                    />
                                                                </>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </section>

                            </div>

                            <DeleteModal
                                show={showModal}
                                onConfirm={handleDeleteConfirm}
                                onCancel={handleCancel}
                            />

                            {/* Add Reply Section */}
                            
                                <div className={`${style.addReplyContainer} mt-3`}>
                                    <div className="mb-3">
                                        <label htmlFor="replyTitle" className="form-label">
                                            Title
                                        </label>
                                        <input
                                            type="text"
                                            id="replyTitle"
                                            className="form-control"
                                            placeholder="Enter title"
                                            value={currentQuickReply.title}
                                            onChange={(e) =>
                                                setCurrentQuickReply((prev) => ({ ...prev, title: e.target.value }))
                                            }
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="replyText" className="form-label">
                                            Text
                                        </label>
                                        <textarea
                                            id="replyText"
                                            className={`${style.textarea} form-control`}
                                            placeholder="Type your quick reply here..."
                                            value={currentQuickReply.text}
                                            onChange={(e) =>
                                                setCurrentQuickReply((prev) => ({ ...prev, text: e.target.value }))
                                            }
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter" && !e.shiftKey && currentQuickReply.title && currentQuickReply.text && !isAdding) {
                                                    e.preventDefault();
                                                    handleAdd();
                                                }
                                                if (e.key === "Enter" && e.shiftKey) {
                                                    // Allow default behavior to add a new line
                                                }
                                            }}
                                        />
                                    </div>

                                    <button
                                        className={`${style.addButton} btn btn-primary w-100`}
                                        onClick={handleAdd}
                                        disabled={!currentQuickReply.title || !currentQuickReply.text || isAdding}
                                    >
                                        {isAdding ? "Adding..." : "Add Reply"}
                                    </button>
                                </div>
                            
                        </>

                    )
                }
            </div>



        </div>

    );
};

export default QuickReply;
