import React from 'react';
import styles from './customerHistoryModal.module.css';
import { Modal } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import { IoCloseSharp } from "react-icons/io5";
const CustomerHistoryModal = ({ show, onRequestClose, customerData }) => {
    const [searchParams] = useSearchParams();

    const mobileParam = searchParams.get("mob");
    const nameParam = searchParams.get("name");
    const mobileNumber = mobileParam ? atob(mobileParam) : null


    if (!customerData) return null;

    return (
        <Modal show={show} onHide={onRequestClose} aria-labelledby="customerHistoryModal" backdrop="static" 
        style={{margin:"2rem auto"}}>
            <div className={styles.modalContent}>
                <div className={styles.modalHeader}>
                    <h2 className={styles.modalTitle}>Customer History</h2>
                    <div onClick={onRequestClose} className={styles.closeIcon}>
                        <IoCloseSharp size={25} />
                    </div>
                </div>

                <div className={styles.scrollableContent}>
                    <div className={styles.modalHeader}>
                        <div>{nameParam} {mobileNumber}</div>
                    </div>
                    {/* Label */}
                    <div className={`${styles.section} mb-3`}>
                        <strong>Label:</strong>
                        {customerData.label[0] ? (
                            <span className={styles.label}>{customerData.label[0]?.name}</span>
                        ) : (
                            <span style={{ marginLeft: "1rem" }}>No Label available</span>
                        )}
                    </div>

                    {/* Conversation Count */}
                    <div className={`${styles.section} mb-3`}>
                        <strong>Conversation Count:</strong> {customerData.conv_count}
                    </div>

                    {/* Reminders */}
                    <div className={styles.section}>
                        <strong>Reminders</strong>
                        {customerData.reminders.length > 0 ? (
                            <div className={styles.items}>
                                {customerData.reminders.map((reminder) => (
                                    <div key={reminder._id} className={styles.item}>
                                        <strong>{reminder.text}</strong>
                                        <div>Set for: {new Date(reminder.date).toLocaleString()}</div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p>No reminders set.</p>
                        )}
                    </div>

                    {/* Notes */}
                    <div className={styles.section}>
                        <strong>Notes</strong>
                        {customerData.notes.length > 0 ? (
                            <div className={styles.items}>
                                {customerData.notes.map((note) => (
                                    <div key={note._id} className={styles.item}>
                                        <strong>{note.text}</strong>
                                        <div>Added on: {new Date(note.date).toLocaleString()}</div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p>No notes available.</p>
                        )}
                    </div>

                    {/* Agents */}
                    <div className={styles.section}>
                        <strong>Agents</strong>
                        {customerData.agents.length > 0 ? (
                            <div className={styles.agentList}>
                                {customerData.agents.map((agent) => (
                                    <span key={agent.agent_id} className={styles.agentBadge}>
                                        {agent.agent_name}
                                    </span>
                                ))}
                            </div>
                        ) : (
                            <p>No agents assigned.</p>
                        )}
                    </div>

                </div>
            </div>
        </Modal>

    );
};

export default CustomerHistoryModal;
