import { useEffect, useState, useContext } from "react";
import { BASE_URL2 } from "../../api/api";
import { ChatState } from "../../context/AllProviders";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import style from './labels.module.css';
import { Dropdown, Button } from 'react-bootstrap';

const Labels = ({labels,currentUser,selectedMobileNumber,setUserLabels,selectedName}) => {
    const [selectedLabel, setSelectedLabel] = useState(); // Track selected label
       

    // Function to handle the assignment of a label to the user immediately on selection
    const handleLabelSelect = async (label) => {
        setSelectedLabel(label); // Update selected label in state

        // Send API request to assign the label to the user
        try {
            const response = await axios.post(`${BASE_URL2}/contact_list`, {
                user_id: currentUser.parent_id,
                token: currentUser.parent_token,
                method:"add",
                channel:"whatsapp",
                list_id:label.id,
                mobile: selectedMobileNumber,
                name:selectedName
            });

            if (response.data.success) {
                setUserLabels(label);

            } else {
                console.error('Error assigning label:', response.data.message);
            }
        } catch (error) {
            console.error("Error assigning label:", error);
        }
    };

    return (
        <div className={style.dropdownContainer}>
            <Dropdown className="w-100">
                <Dropdown.Toggle  id="dropdown-basic">
                    
                    Select a label
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    { labels.length>0?labels.map((label) => (
                        <Dropdown.Item
                            key={label.name}
                            onClick={() => handleLabelSelect(label)} // Handle immediate label assignment
                        >
                            {label.name}
                        </Dropdown.Item>
                    )):
                    <Dropdown.Item
                    key={"No Labels"}>
                    {"No Labels"}
                    </Dropdown.Item>
                    }
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

export default Labels;
