import React, { useState } from "react";
import styles from './autoReplyRules.module.css'
import { use } from "react";

const AutoReplyRules = () => { // Updated Component Name
    const [autoReplies, setAutoReplies] = useState([{ time: "", message: "" }]);
    const [officeStartTime, setOfficeStartTime] = useState("8:00 AM");
    const [officeEndTime, setOfficeEndTime] = useState("10:00 PM");
    const [workingDays, setWorkingDays] = useState([]);
    const [futureDate, setFutureDate] = useState(""); // Future Date Input
    const [futureStartTime, setFutureStartTime] = useState(""); // Future Start Time
    const [futureEndTime, setFutureEndTime] = useState("");
    const [futureDateStatus, setFutureDateStatus] = useState(false);

    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    const addReply = () => {
        if (autoReplies.length < 10) {
            setAutoReplies([...autoReplies, { time: "", message: "" }]);
        }
    };

    // Handle removing a reply
    const removeReply = (index) => {
        const updatedReplies = autoReplies.filter((_, i) => i !== index);
        setAutoReplies(updatedReplies);
    };

    // Handle updating a specific reply
    const updateReply = (index, field, value) => {
        const updatedReplies = autoReplies.map((reply, i) =>
            i === index ? { ...reply, [field]: value } : reply
        );
        setAutoReplies(updatedReplies);
    };

    const handleSubmit = () => {
        console.log("Saved Auto Replies:", autoReplies);
        // Implement your save logic here (e.g., API call)
    };

    const toggleDay = (day) => {
        if (workingDays.includes(day)) {
            setWorkingDays(workingDays.filter((d) => d !== day));
        } else {
            setWorkingDays([...workingDays, day]);
        }
    };

    return (
        <div className={`${styles.autoReplyContainer}`}>

            <h4 className={`mb-4 ${styles.headerTitle}`}>Auto Reply Rules</h4>

            <div className="d-flex flex-column justify-content-center align-items-start mb-4">
                <label className="form-label">Auto Replies Configuration</label>
                {autoReplies.map((reply, index) => (
                    <div key={index} className="d-flex align-items-center w-100 mb-2">
                        <input
                            type="number"
                            min="1"
                            className="form-control me-2"
                            placeholder="Time in minutes"
                            value={reply.time}
                            onChange={(e) => updateReply(index, "time", e.target.value)}
                            required
                        />
                        <input
                            type="text"
                            className="form-control me-2"
                            placeholder="Reply message"
                            value={reply.message}
                            onChange={(e) => updateReply(index, "message", e.target.value)}
                            required
                        />
                        <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => removeReply(index)}
                        >
                            Remove
                        </button>
                    </div>
                ))}

                <div className="w-100 d-flex">
                    {autoReplies.length < 10 && (
                        <button
                            type="button"
                            className="btn btn-primary mt-2 w-50"
                            onClick={addReply}
                        >
                            Add Auto Reply
                        </button>
                    )}

                    <button
                        type="button"
                        className="btn btn-success mt-2 ms-2 w-50"
                        onClick={handleSubmit}
                    >
                        Save Replies
                    </button>

                </div>
            </div>

            {/* Set Office Time */}
            <div className="mb-4">
                <label className="form-label" style={{ fontSize: "1rem", fontWeight: "bold" }}>Set Office Time and Working Days</label>
                <div className="row">
                    <div className="col-md-5">
                        <label className="form-label">Office Start Time</label>
                        <input
                            type="time"
                            className="form-control"
                            value={officeStartTime}
                            onChange={(e) => setOfficeStartTime(e.target.value)}
                        />
                    </div>
                    <div className="col-md-2 text-center mt-4">
                        <span className={styles.timeSeparator}>TO</span>
                    </div>
                    <div className="col-md-5">
                        <label className="form-label">Office End Time</label>
                        <input
                            type="time"
                            className="form-control"
                            value={officeEndTime}
                            onChange={(e) => setOfficeEndTime(e.target.value)}
                        />
                    </div>
                </div>
            </div>

            {/* Working Days */}
            <div className="mb-4">
                <label className="form-label d-block">Working Days</label>
                <div className="d-flex flex-wrap justify-content-evenly">
                    {days.map((day) => (
                        <div key={day} className="form-check me-3">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id={day}
                                checked={workingDays.includes(day)}
                                onChange={() => toggleDay(day)}
                            />
                            <label className="form-check-label" htmlFor={day}>
                                {day}
                            </label>
                        </div>
                    ))}
                </div>
            </div>

            <div className="mb-4">
                <label className="" style={{ fontSize: "1rem", fontWeight: "bold" }}>Auto Reply for Future Date</label>
                <label className="switch">
                    <input
                        type="checkbox"
                        checked={futureDateStatus}
                        onChange={() => setFutureDateStatus(prevState => !prevState)}
                    />
                    <span className="slider round"></span>
                </label>
                {futureDateStatus && <div className="row mt-3">
                    <div className="col-md-4 mb-3">
                        <label className="form-label">Future Date</label>
                        <input
                            type="date"
                            className="form-control"
                            value={futureDate}
                            onChange={(e) => setFutureDate(e.target.value)}
                        />
                    </div>
                    <div className="col-md-4 mb-3">
                        <label className="form-label">Start Time</label>
                        <input
                            type="time"
                            className="form-control"
                            value={futureStartTime}
                            onChange={(e) => setFutureStartTime(e.target.value)}
                        />
                    </div>
                    <div className="col-md-4 mb-3">
                        <label className="form-label">End Time</label>
                        <input
                            type="time"
                            className="form-control"
                            value={futureEndTime}
                            onChange={(e) => setFutureEndTime(e.target.value)}
                        />
                    </div>
                </div>}
            </div>

            {/* Buttons */}
            <div className="d-flex justify-content-end">
                {/* <button className="btn btn-secondary me-2">Close</button> */}
                <button className={`btn btn-primary ${styles.saveButton}`}>
                    Save Changes
                </button>
            </div>

        </div>
    );
};

export default AutoReplyRules; // Updated Component Export Name
