import { useState,useEffect } from "react";


export const useDebounce=(value,delay)=>{
    
    const[debouncedvalue,setDebouncedValue]=useState(value.trim());

    useEffect(()=>{
        const handler= setTimeout(()=>{
            setDebouncedValue(value.trim());
        },delay)


        return()=>{
            clearTimeout(handler);
        }

    },[value,delay])

    return debouncedvalue;
}