import {
    TbMessage2Pin,
    TbMessage2Bolt,
    TbMessage2Plus,
    TbMessage2Check,
    TbMessage2Share,
    TbMessage2X,
} from "react-icons/tb";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ChatState } from "../../context/AllProviders";
import style from './overviewCard.module.css'
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
const OverviewCard = ({ title, value, data, loading }) => {
    const navigate = useNavigate();
    const { setFilteredChats } = ChatState();


    const handleClick = (title) => {
        if (data.length === 0) return;
        const encodedTitle = btoa(title);
        setFilteredChats([...data]);
        navigate(`/dashboard/?disable=true&filter=${encodedTitle}`)
    }

    // const getIcon = () => {
    //     switch (title) {
    //         case "Total Chats":
    //             return (
    //                 <span className="iconCircluate yellowish mr-2">
    //                     <TbMessage2Pin className="text-white" />
    //                 </span>
    //             );
    //         case "Live Chats":
    //             return (
    //                 <span className="iconCircluate voiletish mr-2">
    //                     <TbMessage2Bolt className="text-white" />
    //                 </span>
    //             );
    //         case "Resolved Chats":
    //             return (
    //                 <span className="iconCircluate blueish mr-2">
    //                     <TbMessage2Check className="text-white" />
    //                 </span>
    //             );
    //         case "Read Chats":
    //             return (
    //                 <span className="iconCircluate blueish mr-2">
    //                     <TbMessage2Check className="text-white" />
    //                 </span>
    //             );
    //         case "Unread Chats":
    //             return (
    //                 <span className="iconCircluate greenish mr-2">
    //                     <TbMessage2Check className="text-white" />
    //                 </span>
    //             );
    //         case "Repeated Chats":
    //             return (
    //                 <span className="iconCircluate yellowish mr-2">
    //                     <TbMessage2Check className="text-white" />
    //                 </span>
    //             );
    //         case "Average Response Time":
    //             return (
    //                 <span className="iconCircluate orangeish mr-2">
    //                     <TbMessage2Plus className="text-white" />
    //                 </span>
    //             );
    //         case "Customer Satisfaction":
    //             return (
    //                 <span className="iconCircluate greenish mr-2">
    //                     <TbMessage2Share className="text-white" />
    //                 </span>
    //             );
    //         default:
    //             return (
    //                 <span className="iconCircluate reddish mr-2">
    //                     <TbMessage2X className="text-white" />
    //                 </span>
    //             );
    //     }
    // };
    return (
        <div className={`${style.overviewCard} ${data.length === 0 ? style.disabledCard : ''}`} role="button"
            onClick={() => handleClick(title)}>
            {/* <div className="mr-4">{getIcon()}</div> */}
            <div className="w-100">
                {loading.report ? (
                    // Skeleton loader for title and value
                    <>
                        <Skeleton variant="text" width="80%" height={20} />
                        <Skeleton variant="text" width="50%" height={32} />
                    </>
                ) : (
                    // Actual content
                    <>
                        <div className="font-semibold">{title}</div>
                        <h5
                            className="font-bold text-danger"
                            style={{ textShadow: "none" }}
                        >
                            {value}
                        </h5>
                    </>
                )}
            </div>
        </div>
    )
}

export default OverviewCard;