import React from "react";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import {
    DataGrid, GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useState, useContext } from "react";
import { ChatState } from "../../context/AllProviders";
import { CircularProgress } from '@mui/material';
import { AuthContext } from "../../context/AuthContext";
import { BASE_URL2 } from "../../api/api";
import axios from 'axios';

const Table = ({ data, onLoadMore, setCustomerData, setModalOpen }) => {
    const navigate = useNavigate();
    const [chatLoading, setChatLoading] = useState(false);
    const { currentUser } = useContext(AuthContext);
    const { handleChatOpen } = ChatState();

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 5,
    });

    if (!data || data.length === 0) {
        return <p>No data available</p>;
    }

    const handleCustomerClick = async (mobileNumber,name) => {
        try {
            setChatLoading(true);
            const response = await axios.post(`${BASE_URL2}/whatsapp_report`, {
                user_id: currentUser.parent_id,
                token: currentUser.parent_token,
                method: "customer_history",
                mobile: mobileNumber,
                brand_number: currentUser.brand_number,


            })
            if (response.data.success) {
                setCustomerData(response.data.data);
                const encodedMobile = btoa(mobileNumber)
                setModalOpen(true);
                navigate(`/agent-management/report?mob=${encodedMobile}&name=${name}`)
            }

        } catch (error) {
            console.log(error)

        } finally {
            setChatLoading(false);
        }

    }

    const handleClick = async (chat) => {
        setChatLoading(true);
        await handleChatOpen(chat);
        setChatLoading(false);
        const encodedMobile = btoa(chat.mobile)
        navigate(`/dashboard/?mob=${encodedMobile}`)
    }


    const csvColumns = Object.keys(data[0]).map((key) => {
        if (key === "View Chat" || key === "Last Message") {
            return null; // Skip these fields
        }
        return {
            field: key,
            headerName: key,
            flex: 1, // Flex allows dynamic column resizing
            renderCell: (params) =>
                key === "History" ? (
                    <Link
                        to={params.value}
                        style={{ textDecoration: "none", color: "blue", cursor: "pointer" }}
                    >
                        View Report
                    </Link>
                ) : key === "View Chat" ? (
                    <span
                        onClick={() => handleClick(params.value)}
                        style={{ textDecoration: "underline", color: "blue", cursor: "pointer" }}
                    >
                        View
                    </span>
                ) : key === "Status" ? (
                    <span
                        style={params.value ? { color: "red" } : { color: "green" }}
                    >
                        {params.value ? 'Inactive' : 'Active'}
                    </span>

                ) : key === "Chat Status" ? (
                    <span
                        style={params.value === "Active" ? { color: "green" } : params.value === "Waiting" ? { color: "red" } : { color: "" }}
                    >
                        {params.value}
                    </span>

                ) : (
                    params.value
                )
        };
    }).filter(column => column !== null);



    const columns = Object.keys(data[0]).map((key) => {
        if (key === "Last Message Csv") {
            return null
            // Exclude this column entirely
        }
        return {
            field: key,
            headerName: key,
            flex: 1, // Flex allows dynamic column resizing
            renderCell: (params) => {
                if (key === "Customer Number" || key === "Customer Name") {
                    // Get the current row's "Customer Number" and "Customer Name" values
                    const customerNumber = params.row["Customer Number"];
                    const customerName = params.row["Customer Name"];
                    return (
                        <span
                            style={{ color: "blue", cursor: "pointer" }}
                            onClick={() => handleCustomerClick(customerNumber, customerName)}
                        >
                            {params.value}
                        </span>
                    );
                }
                return key === "History" ? (
                    <Link
                        to={params.value}
                        style={{ textDecoration: "none", color: "blue", cursor: "pointer" }}
                    >
                        View Report
                    </Link>
                ) : key === "View Chat" ? (
                    <span
                        onClick={() => handleClick(params.value)}
                        style={{ textDecoration: "underline", color: "blue", cursor: "pointer" }}
                    >
                        View
                    </span>
                ) : key === "Status" ? (
                    <span
                        style={params.value ? { color: "red" } : { color: "green" }}
                    >
                        {params.value ? 'Inactive' : 'Active'}
                    </span>

                ) : key === "Chat Status" ? (
                    <span
                        style={params.value === "Active" ? { color: "green" } : params.value === "Waiting" ? { color: "red" } : { color: "" }}
                    >
                        {params.value}
                    </span>

                )
                    //  : key === "Customer Number" ? (
                    //     <span style={{ color: "blue", cursor: "pointer" }} onClick={() => handleCustomerClick(params.value)}>
                    //         {params.value}
                    //     </span>

                    // )
                    : (
                        params.value
                    )
            }
        };
    }).filter((column => column !== null));

    const rows = data.map((row, index) => ({
        id: index, // Assign a unique ID to each row
        ...row,
    }));

    const handlePaginationChange = (model) => {
        const totalPages = Math.ceil(data.length / model.pageSize); // Calculate total pages
        setPaginationModel(model);

        if (model.page + 1 === totalPages) {
            // Trigger function when on the last page
            if (onLoadMore) onLoadMore();
        }
    };

    const CustomToolbar = () => (
        <GridToolbarContainer>
            <GridToolbarExport
                csvOptions={{
                    fields: csvColumns.map(column => column.field),
                }}
            />
            <GridToolbarFilterButton />
            <GridToolbarQuickFilter debounceMs={500} />
        </GridToolbarContainer>
    );


    return (
        <>
            {chatLoading && (
                <div
                    style={{
                        position: "fixed",
                        zIndex: 100,
                        top: 0,
                        left: 0,
                        width: "100vw",
                        height: "100vh",
                        backgroundColor: "rgba(0, 0, 0,.05)", // Semi-transparent black background
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backdropFilter: "blur(1px)", // Adds blur effect
                    }}
                >
                    <CircularProgress />
                </div>
            )}

            <Box sx={{ width: "100%" }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: paginationModel,
                        },
                    }}
                    pageSizeOptions={[5, 10, 20]}
                    paginationModel={paginationModel}
                    onPaginationModelChange={handlePaginationChange}
                    autoHeight
                    disableSelectionOnClick
                    slots={{
                        toolbar: CustomToolbar,
                    }}
                />
            </Box>
        </>

    )

};

export default Table;