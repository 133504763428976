import { Modal, Button, Form } from "react-bootstrap";
import { CiEdit } from "react-icons/ci";
import { MdDelete } from "react-icons/md";
import { useEffect, useState, useContext, useRef } from "react";
import style from "./reminder.module.css";
import axios from "axios";
import { BASE_URL2 } from "../../api/api";
import { ChatState } from "../../context/AllProviders";
import { AuthContext } from "../../context/AuthContext";
import { toast } from "react-toastify";
import DeleteModal from "../DeleteModal/DeleteModal";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CircularProgress, Box } from '@mui/material';
import { parseReminder } from "../../utils/Utils";
const Reminder = ({ reminders, loading, setReminders, groupedReminders, fetchType }) => {
    const [isAdding, setIsAdding] = useState(false);
    const { currentUser } = useContext(AuthContext);
    const { selectedMobileNumber, selectedName } = ChatState();
    const [showAddModal, setShowAddModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [newReminder, setNewReminder] = useState({
        date: "",
        text: "",
    });
    const [selectedReminder, setSelectedReminder] = useState(null);
    const [editingReminder, setEditingReminder] = useState(null);
    const todaySectionRef = useRef(null);

    useEffect(() => {
        if (todaySectionRef.current) {
            todaySectionRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    }, [])

    const isFormComplete = editingReminder
        ? editingReminder.text.trim() !== "" &&
        editingReminder.date.trim() !== "" // Check for combined date & time
        : newReminder.text.trim() !== "" &&
        newReminder.date.trim() !== ""


    const addReminder = async () => {
        if (newReminder.text === "" || newReminder.date === "") return;

        // Optimistically update the UI before the server response
        const optimisticReminder = {
            user_id: currentUser.parent_id,
            brand_number: currentUser.brand_number,
            mobile: selectedMobileNumber,
            text: selectedName + " " + selectedMobileNumber + ":" + newReminder.text,
            agent_id: currentUser.user_type === "admin" ? 0 : currentUser.user_id,
            agent_name: currentUser.user_type === "admin" ? "admin" : currentUser.name,
            date: newReminder.date,
            _id: "temp-id" // Temporary ID until the server returns the real one
        };

        // Add the reminder to the UI immediately
        setReminders((prevReminders) => [...prevReminders, optimisticReminder]);
        setNewReminder({ date: "", text: "" });
        setIsAdding(true);
        setShowAddModal(false);

        const body = {
            user_id: currentUser.parent_id,
            token: currentUser.parent_token,
            method: "create",
            mobile: selectedMobileNumber,
            brand_number: currentUser.brand_number,
            text: selectedName + " " + selectedMobileNumber + ":" + newReminder.text,
            date: newReminder.date,
            user_type: currentUser.user_type,
            agent_name: currentUser.name

        }

        try {
            const response = await axios.post(`${BASE_URL2}/whatsapp_reminder`, body);
            if (response.data.success) {
                setReminders((prevReminders) =>
                    prevReminders.map((reminder) =>
                        reminder._id === "temp-id" ? { ...reminder, _id: response.data.id } : reminder
                    )
                );

            } else {
                // If the API returns an error, remove the reminder from the UI
                setReminders((prevReminders) =>
                    prevReminders.filter((reminder) => reminder._id !== "temp-id")
                );
                toast.error("Failed to add reminder. Please try again.");
            }

        } catch (error) {
            console.error("Error adding reminder:", error);
            setReminders((prevReminders) =>
                prevReminders.filter((reminder) => reminder._id !== "temp-id")
            );
            toast.error("Error adding reminder. Please try again.");
        }
        finally {
            setIsAdding(false);
        }
    };

    const handleDeleteClick = (reminder) => {
        setSelectedReminder(reminder);
        setShowModal(true);

    }

    const handleDeleteConfirm = async () => {

        const previousReminders = [...reminders];

        const updatedReminders = reminders.filter((reminder) => reminder._id !== selectedReminder);

        setReminders(updatedReminders);
        setShowModal(false);

        const body = {
            user_id: currentUser.parent_id,
            token: currentUser.parent_token,
            method: "delete",
            brand_number: currentUser.brand_number,
            id: selectedReminder
        };
        setSelectedReminder(null);

        try {

            const response = await axios.post(`${BASE_URL2}/whatsapp_reminder`, body);
            if (response.data.success) {

                toast.success("Reminder deleted successfully")
            } else {
                setReminders(previousReminders);
                toast.error("Failed to delete the reminder. Please try again.");

            }

        } catch (error) {

            console.error("Error deleting reminder:", error);
            setReminders(previousReminders);
            toast.error("Failed to delete the reminder. Please try again.");

        }

    };

    const handleCancel = () => {
        setShowModal(false);
        setSelectedReminder(null);
    };

    const handleEditClick = (reminder) => {
        setEditingReminder(reminder);
        setShowAddModal(true);
    };




    const updateReminder = async () => {

        const previousReminders = [...reminders];

        const updatedReminder = reminders.map((r) =>
            r._id === editingReminder._id
                ? { ...r, text: editingReminder.text, date: editingReminder.date }
                : r
        );
        setReminders(updatedReminder);

        const body = {
            user_id: currentUser.parent_id,
            token: currentUser.parent_token,
            method: "update",
            brand_number: currentUser.brand_number,
            date: editingReminder.date,
            id: editingReminder._id,
            text: editingReminder.text,
        };


        try {
            const response = await axios.post(`${BASE_URL2}/whatsapp_reminder`, body);
            if (response.data.success) {

                toast.success("Reminder updated successfully");
            } else {
                setReminders(previousReminders);
                toast.error("Failed to update the reminder. Please try again.");
            }

        } catch (error) {
            console.error("Error saving reminder:", error);
            setReminders(previousReminders);
            toast.error("Failed to update the reminder. Please try again.");

        } finally {
            setEditingReminder(null);
            setShowAddModal(false);
        }
    }


    return (
        <div className={`${style.reminderCard} reminder-container`}>
            <div className={style.header}>
                <h5 className="">{fetchType !== "retrieve_all" ? "Reminders" : "All Reminders"}</h5>
                {fetchType !== "retrieve_all" ? <button className={`${style.addButton} btn-primary`} onClick={() => setShowAddModal(true)}>
                    Add Reminder
                </button> : null}
            </div>

            {loading ? (<Box display="flex" justifyContent="center" alignItems="center" height="50vh">
                <CircularProgress />
            </Box>) : (<div className={style.reminderContainer}>
                {/* Previous Reminders Section */}
                <div className={style.reminderSubCon}>
                    <h6 key={1} className={style.sectionTitle}>Previous Reminders</h6>
                    <ul className={style.reminderList}>
                        {groupedReminders.previous.length ? (
                            groupedReminders.previous
                                .sort(
                                    (a, b) =>
                                        new Date(a.date.replace(" ", "T")) -
                                        new Date(b.date.replace(" ", "T"))
                                )
                                .map((reminder) => {
                                    const reminderDate = new Date(
                                        reminder.date.replace(" ", "T")
                                    );
                                    const { name, number, reminderText } = parseReminder(reminder.text);
                                    return (
                                        <li key={reminder._id}>
                                            <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-start">
                                                <span className={style.reminderName}>{name.length > 6 ? name.substring(0, 6) + "…" : name} {number}</span>
                                                <span style={{ color: "red", margin: ".5rem 0" }} className={style.reminderText}>{reminderText}</span>
                                                <span className={style.date}>
                                                    {reminderDate.toLocaleDateString("en-GB")} at{" "}
                                                    {reminderDate.toLocaleTimeString([], {
                                                        hour: "2-digit",
                                                        minute: "2-digit",
                                                    })}
                                                </span>

                                            </div>
                                            <div className={style.options}>
                                                <CiEdit
                                                    onClick={() => handleEditClick(reminder)}
                                                    className="clickable mx-1"
                                                />
                                                <MdDelete
                                                    onClick={() => handleDeleteClick(reminder._id)}
                                                    className="clickable mx-1"
                                                />
                                            </div>
                                        </li>
                                    );
                                })
                        ) : (
                            <p>No previous reminders.</p>
                        )}
                    </ul>
                </div>

                {/* Today's Reminders Section */}
                <div className={style.reminderSubCon} ref={todaySectionRef}>
                    <h6 className={style.sectionTitle}>Today's Reminders</h6>
                    <ul className={style.reminderList}>
                        {groupedReminders.today.length ? (
                            groupedReminders.today
                                .sort(
                                    (a, b) =>
                                        new Date(a.date.replace(" ", "T")) -
                                        new Date(b.date.replace(" ", "T"))
                                )
                                .map((reminder) => {
                                    const reminderDate = new Date(
                                        reminder.date.replace(" ", "T")
                                    );
                                    const { name, number, reminderText } = parseReminder(reminder.text);
                                    return (
                                        <li key={reminder._id}>
                                            <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-start">
                                                <span className={style.reminderName}>{name.length > 6 ? name.substring(0, 6) + "…" : name} {number}</span>
                                                <span style={{ color: "green", margin: ".5rem 0" }} className={style.reminderText}>{reminderText}</span>
                                                <span className={style.date}>
                                                    {reminderDate.toLocaleDateString("en-GB")} at{" "}
                                                    {reminderDate.toLocaleTimeString([], {
                                                        hour: "2-digit",
                                                        minute: "2-digit",
                                                    })}
                                                </span>

                                            </div>
                                            <div className={style.options}>
                                                <CiEdit
                                                    onClick={() => handleEditClick(reminder)}
                                                    className="clickable mx-1"
                                                />
                                                <MdDelete
                                                    onClick={() => handleDeleteClick(reminder._id)}
                                                    className="clickable mx-1"
                                                />
                                            </div>
                                        </li>
                                    );
                                })
                        ) : (
                            <p>No reminders for today.</p>
                        )}
                    </ul>
                </div>

                {/* Upcoming Reminders Section */}
                <div className={style.reminderSubCon}>
                    <h6 className={style.sectionTitle}>Upcoming Reminders</h6>
                    <ul className={style.reminderList}>
                        {groupedReminders.upcoming.length ? (
                            groupedReminders.upcoming
                                .sort(
                                    (a, b) =>
                                        new Date(a.date.replace(" ", "T")) -
                                        new Date(b.date.replace(" ", "T"))
                                )
                                .map((reminder) => {
                                    const reminderDate = new Date(
                                        reminder.date.replace(" ", "T")
                                    );
                                    const { name, number, reminderText } = parseReminder(reminder.text);
                                    return (
                                        <li key={reminder._id}>
                                            <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-start">
                                                <span className={style.reminderName}>{name.length > 6 ? name.substring(0, 6) + "…" : name} {number}</span>
                                                <span style={{ color: "blue", margin: ".5rem 0" }} className={style.reminderText}>{reminderText}</span>
                                                <span className={style.date}>
                                                    {reminderDate.toLocaleDateString("en-GB")} at{" "}
                                                    {reminderDate.toLocaleTimeString([], {
                                                        hour: "2-digit",
                                                        minute: "2-digit",
                                                    })}
                                                </span>

                                            </div>
                                            <div className={style.options}>
                                                <CiEdit
                                                    onClick={() => handleEditClick(reminder)}
                                                    className="clickable mx-1"
                                                />
                                                <MdDelete
                                                    onClick={() => handleDeleteClick(reminder._id)}
                                                    className="clickable mx-1"
                                                />
                                            </div>
                                        </li>
                                    );
                                })
                        ) : (
                            <p>No upcoming reminders.</p>
                        )}
                    </ul>
                </div>
            </div>)}

            <DeleteModal
                show={showModal}
                onConfirm={handleDeleteConfirm}
                onCancel={handleCancel}
            />

            {/* Modal for Adding Reminder */}
            <Modal show={showAddModal} onHide={() => setShowAddModal(false)} className="reminder-modal" backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{editingReminder ? "Edit Reminder" : "Add Reminder"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="p-3">
                        {/* Reminder Text */}
                        <Form.Group controlId="reminderText" className="mb-4">
                            <Form.Label className="fw-bold text-secondary">Reminder Text</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter reminder"
                                value={editingReminder ? editingReminder.text : newReminder.text}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const maxLength = 100;
                                    if (value.length <= maxLength) {
                                        editingReminder
                                            ? setEditingReminder({ ...editingReminder, text: value })
                                            : setNewReminder({ ...newReminder, text: value });
                                    }
                                }}
                                className="rounded-3 shadow-sm"
                            />
                        </Form.Group>

                        {/* Date and Time Picker */}
                        <Form.Group controlId="reminderDateTime"
                            className="d-flex flex-column justify-content-center align-items-start mb-4">
                            <Form.Label className="  fw-bold text-secondary ">Date & Time</Form.Label>
                            <div className="w-100 d-flex justify-content-center">
                                <ReactDatePicker
                                    selected={
                                        editingReminder
                                            ? new Date(editingReminder.date.replace(" ", "T")) // Convert "YYYY-MM-DD HH:mm" to ISO
                                            : newReminder.date
                                                ? new Date(newReminder.date.replace(" ", "T")) // Handle the same format for new reminders
                                                : null
                                    }
                                    onChange={(date) => {
                                        // Format the selected date into "YYYY-MM-DD HH:mm" in local timezone
                                        const year = date.getFullYear();
                                        const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
                                        const day = String(date.getDate()).padStart(2, "0");
                                        const hours = String(date.getHours()).padStart(2, "0");
                                        const minutes = String(date.getMinutes()).padStart(2, "0");

                                        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`; // Combine date and time in local timezone

                                        if (editingReminder) {
                                            setEditingReminder({ ...editingReminder, date: formattedDate }); // Update editingReminder
                                        } else {
                                            setNewReminder({ ...newReminder, date: formattedDate }); // Update newReminder
                                        }
                                    }}
                                    showTimeSelect
                                    timeIntervals={5} // Interval of 15 minutes for time selection
                                    dateFormat="yyyy-MM-dd HH:mm" // Match the "YYYY-MM-DD HH:mm" format
                                    placeholderText="Select date and time"
                                    className={`form-control rounded-3 shadow-sm`}
                                    minDate={new Date()}
                                />
                            </div>
                        </Form.Group>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setEditingReminder(null);
                            setNewReminder({ date: "", text: "" });
                            setShowAddModal(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        onClick={editingReminder ? updateReminder : addReminder}
                        disabled={!isFormComplete || isAdding}
                    >
                        {editingReminder ? "Update Reminder" : "Save Reminder"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>


    );
};

export default Reminder;
