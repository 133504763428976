import React from "react";
import styles from "./inputBar.module.css" // Import the CSS module
import { useContext, useState, useEffect,useRef } from "react";
import { ChatContext } from "../../context/ChatContext";
import Input from "../Input";
import useSentences from "../../customHooks/useSentences";
import { ChatState } from "../../context/AllProviders";
const Inputbar = ({ notes, setshowNotesCard, setShowContactDetail, activeTab, setActiveTab, setshowQuickReply }) => {
    const { data } = useContext(ChatContext);
    const { setText } = ChatState();
    const textareaRef = useRef(null);
    const { filteredSentences, saveSentences, splitParagraphIntoSentences } = useSentences();

    const handleSuggestionClick = (suggestedText) => {
        setText((prevState) => {
            // Split the current input into words
            const words = prevState.split(/\s+/);

            // Check if the last word matches the suggestion
            const lastWord = words[words.length - 1];
            const suggestionFirstWord = suggestedText.split(/\s+/)[0];

            if (suggestionFirstWord.toLowerCase().includes(lastWord.toLowerCase())) {
                // If the last word matches the suggestion, replace it
                words[words.length - 1] = suggestedText;
            } else {
                // Otherwise, append the suggestion
                words.push(suggestedText);
            }
            // Join the words back into a single string and return
            return words.join(" ");
        });
        textareaRef.current?.focus()
    };
    const handleTabClick = (tabName) => {
        setActiveTab(tabName);

        if (tabName === 'Note') {
            setshowNotesCard((prevState) => {
                const newState = !prevState; // Calculate the new state
                if (!newState) {
                    // If the new state is `false`, reset the active tab to ""
                    setActiveTab("");
                }
                return newState;
            });
            setShowContactDetail(false);
            setshowQuickReply(false);

        } else if (tabName === "quickReplies") {
            setshowQuickReply((prevState) => {
                const newState = !prevState; // Calculate the new state
                if (!newState) {
                    // If the new state is `false`, reset the active tab to ""
                    setActiveTab("");
                }
                return newState;
            });
            setShowContactDetail(false);
            setshowNotesCard(false);
        }

    };


    return (
        <div className={styles.inputContainer}>
            <header className="d-flex justify-content-start align-items-center w-100">

                <button
                    onClick={() => handleTabClick('Note')}
                    className={`btn ${styles.button} ms-2 ${activeTab === 'Note' ? styles.activeButton : ''}`}
                >
                    Notes {notes.length > 0 ? `(${notes.length})` : ''}
                </button>
                <button
                    onClick={() => handleTabClick('quickReplies')}
                    className={`btn ${styles.button} ms-2 ${activeTab === 'quickReplies' ? styles.activeButton : ''}`}
                >
                    Quick Replies
                </button>
            </header>



            {/* Suggestion Section */}
            {filteredSentences.length ? <div className={styles.suggestionsContainer}>
                <ul className={styles.suggestionsList}>

                    {filteredSentences.map((sentence, index) => (
                        <li key={index} className={styles.suggestionPill} onClick={() => handleSuggestionClick(sentence)}>
                            {sentence}
                        </li>
                    ))}
                </ul>
                <div className="d-none d-md-flex justify-content-center align-items-end w-100" style={{ fontSize: ".7rem", color: "grey" }}>
                    <div>Press Tab to add text</div>
                </div>
            </div> : null}


            <div className={styles.textArea}>

                <Input textareaRef={textareaRef} handleSuggestionClick={handleSuggestionClick} saveSentences={saveSentences} splitParagraphIntoSentences={splitParagraphIntoSentences} filteredSentences={filteredSentences} selectedMobile={data.selectedMobile} convData={data} />
            </div>


        </div>
    );
};

export default Inputbar;
