import React, { useState, useEffect } from "react";
import axios from "axios";
import { ChatState } from "../context/AllProviders";
import { useDebounce } from "./useDebounce";
const useSentences = () => {
    const [localSentences, setLocalSentences] = useState([]);
    const [filteredSentences, setFilteredSentences] = useState([]);
    const { text } = ChatState();

    const debouncedInputText = useDebounce(text, 300);

    const splitParagraphIntoSentences = (text) => {
        // Trim the input to avoid leading/trailing spaces
        const trimmedText = text.trim();

        // If the text doesn't contain any punctuation, treat it as a single sentence
        if (!/[.!?]/.test(trimmedText)) {
            return [trimmedText]; // Return the whole paragraph as one sentence
        }

        // Split the text based on punctuation marks (., !, ?)
        const sentenceRegex = /[^.!?]+[.!?]*\s*/g;
        return trimmedText.match(sentenceRegex) || [trimmedText]; // Fallback to entire text if no match
    };

    useEffect(() => {
        // Filter the sentences based on the debounced input text
        const extractLastWord = (input) => {
            const words = input.split(/\s+/); // Split the input into words by spaces
            return words[words.length - 1].toLowerCase(); // Get the last word and convert to lowercase
        };

        if (debouncedInputText.trim()) {
            const lastWord = extractLastWord(debouncedInputText);

            if (lastWord) {
                const filtered = localSentences.filter((sentence) =>
                    sentence.toLowerCase().includes(lastWord)
                );
                setFilteredSentences(filtered);

            } else {
                setFilteredSentences([]);
            }
        } else {
            setFilteredSentences([]);
        }
    }, [debouncedInputText]);

    useEffect(() => {
        const fetchSentencesFromLS = () => {
            try {
                
                const savedSentences = JSON.parse(localStorage.getItem('sentences')) || [];

                setLocalSentences(savedSentences);
               

            } catch (error) {
                console.error('Error fetching sentences from the database:', error);
            }
        }

        fetchSentencesFromLS();

    }, [])


    const saveSentences = (sentence) => {
        const savedSentences = JSON.parse(localStorage.getItem('sentences')) || [];

        const lowerCaseSentences = savedSentences.map((s) => s.toLowerCase());

        if (!lowerCaseSentences.includes(sentence.toLowerCase())) {
            const updatedSentences = [...savedSentences, sentence];
            localStorage.setItem('sentences', JSON.stringify(updatedSentences));
            setLocalSentences(updatedSentences);

        }
    }

    return {
        localSentences,
        filteredSentences,
        setFilteredSentences,
        saveSentences,
        splitParagraphIntoSentences
    }

}

export default useSentences;