import ChartSection from "../ReportSections/ChartSection";
import OverviewCard from "../ReportSections/OverviewCard";
import Table from "../ReportSections/Table";
import React, { useState } from "react";
import style from './newDashboard.module.css'
import { IoCloseSharp } from "react-icons/io5";
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import CustomerHistoryModal from '../CustomerHistory/CustomerHistoryModal';
import { useNavigate } from "react-router-dom";

const NewDashboard = ({ fetchNextPage, currentUser, chats,
  brand_number, todayReport, reportSummary, agentList,
  selectFilter, handleFilterChange, handleDateChange,
  startDate, endDate, calendarOpen, handleOpenCalendar,
  setCalendarOpen, loading }) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [customerData, setCustomerData] = useState(null);
  const { total_chat, live_chat, answer_chat, missed_chat, total_repeat, agentwise_all_chat: agentWiseReport } = todayReport;

  const toggleView = (tab) => {
    setActiveTab(tab);
  };

  const closeModal = () => {
    navigate('/agent-management/report');
    setModalOpen(false);

  }



  let chatDataByDay = [];

  if (reportSummary && reportSummary.length > 0) {
    chatDataByDay = reportSummary.map((item) => ({
      Date: item.date,
      "Total Chats": Array.isArray(item.total_chat)?item.total_chat.length:item.total_chat,
      "Read Chats": item.read_chat,
      "Unread Chats": item.unread_chat,
      "Repeated Chats": item.repeated_chat,
    }));
  }
  if (selectFilter === 'thisWeek' || selectFilter === 'thisMonth') {
    chatDataByDay.push({
      "Total Chats": total_chat.length,
      Date: "Today",
      "Read Chats": answer_chat.length,
      "Unread Chats": missed_chat.length,
      "Repeated Chats": total_repeat.length
    })
  }

  let agentPerfData = [];
  if (agentWiseReport && agentWiseReport.length > 0) {
    agentPerfData = agentWiseReport.map((item) => ({
      "Agent ID": item.agent_id,
      "Agent Name": item.agent_name,
      "Total Chats": item.total_chat?.length,
      "Read Chats": item.answer_chat?.length,
      "Unread Chats": item.missed_chat?.length,
      "Repeated Chats": item.total_repeat?.length,
      "New Chats": item.new_chat?.length,
    }))

  }
  const topChats = chats.map(chat => {
    const readableTime = new Date(chat.created).toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true, // Use 12-hour format; set to false for 24-hour format
    });

    const givenDate = new Date(chat.created);
    const currentDate = new Date();
    const time_23hrs_59min_ago = new Date(
      currentDate.getTime() - (23 * 60 * 60 * 1000 + 59 * 60 * 1000)
    );
    const isOldMsg = givenDate < time_23hrs_59min_ago;

    return {
      "Customer Name": chat.name,
      "Customer Number": chat.mobile,
      "Agent Name": chat.agent_name,
      // "Total Conversations": 0,
      // "Labels": "Hot",
      "Status": isOldMsg,
      "Team": chat.team_name,
      "Last Message Csv": chat.content,
      "Last Message": (
        <span style={{ color: chat.req_from === 'USER' ? 'blue' : 'red' }}>
          {chat.req_from === 'USER' ? 'User: ' : 'Agent: '} <span style={{ color: "black" }}>{chat.content}</span>
        </span>
      ),
      "Time": readableTime,
      "View Chat": chat,

    }
  });

  const formatDate = (date) => {
    const options = { timeZone: 'Asia/Kolkata', year: 'numeric', month: '2-digit', day: '2-digit' };
    const formattedDate = new Date(date).toLocaleString('en-IN', options);
    const [day, month, year] = formattedDate.split('/');
    return `${year}-${month}-${day}`;
  };
  const getPlaceholderText = () => {

    if (startDate && endDate) {
      return `${formatDate(startDate)} / ${formatDate(endDate)}`;
    } else if (startDate) {
      return `${formatDate(startDate)} / End date`;
    }
    return "Select a date range";
  };


  return (
    <div className={style.dashboardContainer}>
      <div className="d-flex justify-content-start align-items-center w-100">
        <h4 style={{ fontWeight: "bold" }}>Admin Dashboard</h4>

      </div>

      <main className={style.newDashboardMain}>

        {/* Overview Section */}
        <div className={style.overviewSection}>
          <OverviewCard title="Total Chats" value={total_chat?.length} data={total_chat} loading={loading} />
          <OverviewCard title="Live Chats" value={live_chat?.length} data={live_chat} loading={loading} />
          <OverviewCard title="Read Chats" value={answer_chat?.length} data={answer_chat} loading={loading} />
          <OverviewCard title="Unread Chats" value={missed_chat?.length} data={missed_chat} loading={loading} />
          <OverviewCard title="Repeated Chats" value={total_repeat?.length} data={total_repeat} loading={loading} />
          {/* <OverviewCard title="Average Response Time" value="2m 30s" />
          <OverviewCard title="Customer Satisfaction" value="95%" /> */}
        </div>

        {/* Charts Section */}
        {currentUser.user_type === 'admin' && <div className={style.chartSection}>
          <h5 className=" font-semibold">
            Chat Metrics
          </h5>
          <div className="d-flex flex-column bg-white shadow-lg rounded-lg justify-content-between align-items-center w-100 mb-4 p-3" style={{ borderRadius: "8px" }}>
            <div className={`d-flex justify-content-between align-items-center w-100 mb-4 `}>
              <div className={`d-flex  justify-content-between align-items-center  `}>
                <div className="d-flex justify-content-center align-items-center">
                  <button
                    className={`py-1 px-2 ${activeTab === 1 ? style.activeChartBtn : null
                      }`}
                    style={{
                      borderRight: "none",
                      borderTopLeftRadius: "8px",
                      borderBottomLeftRadius: "8px",
                    }}
                    onClick={() => toggleView(1)}

                  >
                    <i
                      className="bx bx-line-chart"
                      style={{ fontSize: "20px" }}
                    ></i>
                  </button>
                  <button
                    className={`py-1 px-2 ${activeTab === 2 ? style.activeChartBtn : null
                      }`}
                    style={{
                      borderLeft: "none",
                      borderTopRightRadius: "8px",
                      borderBottomRightRadius: "8px",
                    }}
                    onClick={() => toggleView(2)}
                  >
                    <i
                      className="bx bx-table"
                      style={{ fontSize: "20px" }}
                    ></i>
                  </button>
                </div>

              </div>



              <div className={`d-flex ${selectFilter === "custom_date" ? 'justify-content-between' : 'justify-content-end'} align-items-center`}>
                {selectFilter === "custom_date" &&
                  <div className="d-flex mx-2">

                    <DatePicker
                      onChange={handleDateChange}
                      startDate={startDate}
                      endDate={endDate}
                      selectsRange
                      onClickOutside={() => setCalendarOpen(false)}
                      open={calendarOpen}
                      onInputClick={handleOpenCalendar}
                      className="form-control"
                      dateFormat="yyyy-MM-dd"
                      placeholderText={getPlaceholderText()}

                    />

                  </div>
                }
                <select value={selectFilter} onChange={handleFilterChange} className="border rounded p-2" style={{ cursor: "pointer" }}>
                  <option value="thisWeek">This Week</option>
                  <option value="thisMonth">This month</option>
                  <option value="thisYear">This Year</option>
                  <option value="custom_date">Custom</option>
                </select>

              </div>


            </div>
            {activeTab === 1 && (chatDataByDay.length === 0 ? <div>No data Available</div> : <ChartSection title="" type="line" data={chatDataByDay} />)}
            {activeTab === 2 && (chatDataByDay.length === 0 ? <div>No data Available</div> : <Table data={chatDataByDay} />)}

          </div>



        </div>}

        {/* Agent Performance Section */}
        <div className={style.agentSection}>
          <h5 className="text-xl font-semibold mb-3 text-gray-800 w-100">Today's Agent Performance</h5>
          <div className="bg-white shadow-lg  p-4 w-100" style={{ borderRadius: "8px" }}>
            <Table
              data={agentPerfData}
            />
          </div>


        </div>
        {/* <div className={style.agentSection}>
          <h4 className="text-xl font-semibold text-gray-800 w-100">Customer Data</h4>
          <div className="bg-white shadow-lg rounded-lg p-4 w-100">
            <Table
              data={mockCustomerData}
            />
          </div>

        </div> */}

        {/* Chat History Section */}

        <div className={style.ChatSection}>
          <h5 className="text-xl font-semibold text-gray-800 mb-3  w-100">Customers</h5>
          <div className="d-flex justify-content-between align-items-center w-100">
            <div className="bg-white shadow-lg  p-4 w-100" style={{ borderRadius: "8px" }}>
              <Table data={topChats} onLoadMore={fetchNextPage} setCustomerData={setCustomerData} setModalOpen={setModalOpen}
              />
            </div>


          </div>

        </div>

        <CustomerHistoryModal show={modalOpen}
          onRequestClose={closeModal}
          customerData={customerData} />


        {/* User Management Section */}
        {/* <div className={style.userManagement}>
          <h4 className="text-xl font-semibold text-gray-800 w-100">Agent Stats</h4>
          <div className="bg-white shadow-lg rounded-lg p-4 w-100">
            <Table
              data={[
                {
                  "Agent Name": "Ritu",
                  "Status": "Busy",
                  "Active Chats": 3,
                  "Total Coversations":20,
                  "Avg. Response Time": "1m 30s",
                  
                },
                {
                  "Agent Name": "Prince",
                  "Status": "Available",
                  "Active Chats": 1,
                  "Total Coversations":50,
                  "Avg. Response Time": "2m",
                 
                },
                {
                  "Agent Name": "Ashish",
                  "Status": "Busy",
                  "Active Chats": 4,
                  "Total Coversations":100,
                  "Avg. Response Time": "1m",
          
                },
              ]}
             
            />

          </div>

        </div> */}
      </main>
    </div>
  )
}

export default NewDashboard;