import { IoIosContact } from "react-icons/io";
import { IoCloseSharp } from "react-icons/io5";
import style from './contactDetailCard.module.css'
import { useContext, useEffect, useState } from "react";
import Labels from "../Labels/Labels";
import { AuthContext } from "../../context/AuthContext";
import { ChatState } from "../../context/AllProviders";
import axios from "axios";
import { BASE_URL2 } from "../../api/api";
import LabelCard from "../Labels/LabelCard";
import { MdEdit } from "react-icons/md";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
const ContactDetailCard = ({isClosing,setIsClosing, userLabels, setUserLabels, showContactDetail, setShowContactDetail }) => {
    const [isEditMode, setIsEditMode] = useState(false);
    const { currentUser } = useContext(AuthContext);
    const { selectedMobileNumber, selectedName,
        setSelectedName, selectedUserDetails, setSelectedUserDetails, setAllChats } = ChatState();
    const [firstName, lastName] = selectedName?.split(" ") || ["-", "-"];
    const [labels, setLabels] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [details, setDetails] = useState({
        firstName: firstName || '',
        lastName: lastName || '',
        email: selectedUserDetails?.email || '',
        company: selectedUserDetails?.company || '',
        selectedMobileNumber,
    });
    

    useEffect(() => {
        const fetchLabels = async () => {
            const body = {
                user_id: currentUser.parent_id,
                token: currentUser.parent_token,
                method: "wp_list_retrieve",
                mobile: selectedMobileNumber,
                name: selectedName
            };

            try {
                const response = await axios.post(`${BASE_URL2}/contact_list`, body);
                if (response.data.success) {
                    setLabels(response.data.data.all_list); // Store labels if fetch is successful

                }
            } catch (error) {
                console.error("Error fetching labels:", error);
            }
        };

        fetchLabels();
    }, [currentUser, selectedMobileNumber, selectedName]);

   

    const verifyChange = () => {
        return JSON.stringify({
            name: `${details.firstName} ${details.lastName}`,
            email: details.email,
            company: details.company
        }) === JSON.stringify({
            name: selectedName,
            email: selectedUserDetails?.email,
            company: selectedUserDetails?.company
        });
    };

    const isValidEmail = (email) => {
        return email === '' || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };
    

    const handleUpdate = async () => {
        if (!details.firstName.trim() || !details.lastName.trim()) {
            toast.error("First Name and Last Name cannot be empty.");
            return;
        }
        if (!isValidEmail(details.email)) {
            toast.error("Invalid email address.");
            return;
        }
        // Perform save logic, e.g., call backend API
        const body = {
            user_id: currentUser.parent_id,
            token: currentUser.parent_token,
            method: "update_wp_profile",
            brand_number: currentUser.brand_number,
            mobile: selectedMobileNumber,
            email: details.email,
            company: details.company,
            name: details.firstName.trim() + " " + details.lastName.trim()
        }
        try {
            setIsLoading(true);
            const response = await axios.post(`${BASE_URL2}/whatsapp_setting`, body);
            if (response.data.success) {
                if (selectedName !== details.firstName + " " + details.lastName) {
                    setAllChats((prevState) =>
                        prevState.map((chat) => {
                            if (chat.mobile === selectedMobileNumber) {
                                return {
                                    ...chat,
                                    name: details.firstName.trim() + " " + details.lastName.trim()
                                }
                            }
                            return chat
                        })
                    )
                    setSelectedName(details.firstName.trim() + " " + details.lastName.trim())
                }
                if (selectedUserDetails.email !== details.email) {
                    setSelectedUserDetails((prevState) => ({ ...prevState, email: details.email }))
                }
                if (selectedUserDetails.company !== details.company) {
                    setSelectedUserDetails((prevState) => ({ ...prevState, company: details.company }))
                }
                toast.success("Details updated successfully")
            }

        } catch (error) {
            console.log(error);
            toast.error("Unable to update details, please try again later")

        }
        finally {
            setIsEditMode(false)
            setIsLoading(false);
        }

    };

    const handleEditClick = () => {
        setIsEditMode(true);
    };

    const handleCancel = () => {
        setDetails({
            firstName: firstName || '',
            lastName: lastName || '',
            email: selectedUserDetails?.email || '',
            company: selectedUserDetails?.company || '',
            selectedMobileNumber,
        })
        setIsEditMode(false);
    };


    const handleClose = () => {
        setIsClosing(true);
        setTimeout(() => {
            setShowContactDetail(false); // Remove the component after the animation ends
            setIsClosing(false); // Reset the closing state
          }, 200);
    };

   

    const maskNo = (num) => {
        const lastFourDigits = num.slice(-4);
        const maskedNumber = lastFourDigits.padStart(num.length, "X");
        return maskedNumber;
    };



    return (
        <div className={`container ${style.contact} 
        p-2 absolute border rounded shadow-sm d-flex flex-column
         align-items-center
          ${selectedMobileNumber !== null && 
         ( showContactDetail ? (isClosing ? style.animateOut : style.animateIn) : '')}`} >
            <header className="d-flex justify-content-end align-items-center p-2 w-100">

                <IoCloseSharp
                    onClick={handleClose}
                    className={`${style.closeIcon} clickable`}
                />
            </header>

            {/* Name Section */}
            <div className="name-section d-flex align-items-center justify-content-start mb-3 w-100">
                <div className="flex-shrink-0 chat-user-img online user-own-img align-self-center me-3 ms-0">
                    <svg
                        viewBox="0 0 212 212"
                        height="212"
                        width="212"
                        preserveAspectRatio="xMidYMid meet"
                        className="xh8yej3 x5yr21d"
                        version="1.1"
                        x="0px"
                        y="0px"
                        enableBackground="new 0 0 212 212"
                    >
                        <title>default-user</title>
                        <path
                            fill="#DFE5E7"
                            className="background"
                            d="M106.251,0.5C164.653,0.5,212,47.846,212,106.25S164.653,212,106.25,212C47.846,212,0.5,164.654,0.5,106.25 S47.846,0.5,106.251,0.5z"
                        ></path>
                        <g>
                            <path
                                fill="#FFFFFF"
                                className="primary"
                                d="M173.561,171.615c-0.601-0.915-1.287-1.907-2.065-2.955c-0.777-1.049-1.645-2.155-2.608-3.299 c-0.964-1.144-2.024-2.326-3.184-3.527c-1.741-1.802-3.71-3.646-5.924-5.47c-2.952-2.431-6.339-4.824-10.204-7.026 c-1.877-1.07-3.873-2.092-5.98-3.055c-0.062-0.028-0.118-0.059-0.18-0.087c-9.792-4.44-22.106-7.529-37.416-7.529 s-27.624,3.089-37.416,7.529c-0.338,0.153-0.653,0.318-0.985,0.474c-1.431,0.674-2.806,1.376-4.128,2.101 c-0.716,0.393-1.417,0.792-2.101,1.197c-3.421,2.027-6.475,4.191-9.15,6.395c-2.213,1.823-4.182,3.668-5.924,5.47 c-1.161,1.201-2.22,2.384-3.184,3.527c-0.964,1.144-1.832,2.25-2.609,3.299c-0.778,1.049-1.464,2.04-2.065,2.955 c-0.557,0.848-1.033,1.622-1.447,2.324c-0.033,0.056-0.073,0.119-0.104,0.174c-0.435,0.744-0.79,1.392-1.07,1.926 c-0.559,1.068-0.818,1.678-0.818,1.678v0.398c18.285,17.927,43.322,28.985,70.945,28.985c27.678,0,52.761-11.103,71.055-29.095 v-0.289c0,0-0.619-1.45-1.992-3.778C174.594,173.238,174.117,172.463,173.561,171.615z"
                            ></path>
                            <path
                                fill="#FFFFFF"
                                className="primary"
                                d="M106.002,125.5c2.645,0,5.212-0.253,7.68-0.737c1.234-0.242,2.443-0.542,3.624-0.896 c1.772-0.532,3.482-1.188,5.12-1.958c2.184-1.027,4.242-2.258,6.15-3.67c2.863-2.119,5.39-4.646,7.509-7.509 c0.706-0.954,1.367-1.945,1.98-2.971c0.919-1.539,1.729-3.155,2.422-4.84c0.462-1.123,0.872-2.277,1.226-3.458 c0.177-0.591,0.341-1.188,0.49-1.792c0.299-1.208,0.542-2.443,0.725-3.701c0.275-1.887,0.417-3.827,0.417-5.811 c0-1.984-0.142-3.925-0.417-5.811c-0.184-1.258-0.426-2.493-0.725-3.701c-0.15-0.604-0.313-1.202-0.49-1.793 c-0.354-1.181-0.764-2.335-1.226-3.458c-0.693-1.685-1.504-3.301-2.422-4.84c-0.613-1.026-1.274-2.017-1.98-2.971 c-2.119-2.863-4.646-5.39-7.509-7.509c-1.909-1.412-3.966-2.643-6.15-3.67c-1.638-0.77-3.348-1.426-5.12-1.958 c-1.181-0.355-2.39-0.655-3.624-0.896c-2.468-0.484-5.035-0.737-7.68-0.737c-21.162,0-37.345,16.183-37.345,37.345 C68.657,109.317,84.84,125.5,106.002,125.5z"
                            ></path>
                        </g>
                    </svg>
                </div>
                <h4 className="mb-0">{selectedName}</h4>
                <a href="#" className="ms-2 text-muted">
                    <i className="bi bi-box-arrow-up-right"></i>
                </a>
            </div>



            {/* Label Section */}
            <div className={style.labelContainer}>

                {userLabels?.name && (
                    <LabelCard labelData={userLabels} />
                )}

                <Labels labels={labels} selectedName={selectedName} setUserLabels={setUserLabels} currentUser={currentUser} selectedMobileNumber={selectedMobileNumber} />
            </div>

            {/* Contact Details */}
            {isLoading ?
                <div style={{ marginTop: "50%" }}>
                    <CircularProgress />

                </div>
                : isEditMode ?
                    <div className={`form w-100 ${style.contactDetails}`}>
                        <div className="mb-3">
                            <label className="text-muted">Mobile Number</label>
                            <div className="form-control-plaintext">{selectedMobileNumber}</div>
                        </div>
                        <div className="mb-3">
                            <label className="text-muted">First Name</label>
                            <input type="text" className="form-control" value={details.firstName}
                                onChange={(e) => setDetails((prevState) => ({ ...prevState, firstName: e.target.value }))} />
                        </div>
                        <div className="mb-3">
                            <label className="text-muted">Last Name</label>
                            <input type="text" className="form-control"
                                value={details.lastName}
                                onChange={(e) => setDetails((prevState) => ({ ...prevState, lastName: e.target.value }))} />
                        </div>
                        <div className="mb-3">
                            <label className="text-muted">Email</label>
                            <input type="email" className="form-control"
                                value={details.email}
                                onChange={(e) => setDetails((prevState) => ({ ...prevState, email: e.target.value }))} />
                        </div>
                        <div className="mb-3">
                            <label className="text-muted">Company</label>
                            <input type="text" className="form-control"
                                value={details.company}
                                onChange={(e) => setDetails((prevState) => ({ ...prevState, company: e.target.value }))} />
                        </div>


                        <div className="d-flex justify-content-end w-100 mt-3">
                            <button className="btn btn-secondary me-2 w-50" onClick={handleCancel}>
                                Cancel
                            </button>
                            <button className="btn btn-primary w-50"
                                disabled={verifyChange()} onClick={handleUpdate}>
                                Save
                            </button>
                        </div>
                    </div>
                    : <div className={style.contactDetails}>
                        <header className="d-flex justify-content-between align-items-center mb-3">
                            <div className="d-flex">
                                <IoIosContact size={20} className="me-2 text-primary" />
                                <h5 className="mb-0">Contact Details</h5>

                            </div>
                            <div onClick={handleEditClick} className={`${style.edit}`} style={{ cursor: "pointer" }}>
                                <MdEdit size={20} />
                            </div>
                        </header>
                        <main className="d-flex flex-column ">
                            <div className={style.detail}>
                                <label className="text-muted">Mobile Number</label>
                                <div>{currentUser.user_type === "admin" ? selectedMobileNumber : maskNo(selectedMobileNumber)}</div>
                            </div>
                            <div className={style.detail}>
                                <label className="text-muted">First Name</label>
                                <div>{firstName}</div>
                            </div>
                            <div className={style.detail}>
                                <label className="text-muted">Last Name</label>
                                <div>{lastName}</div>
                            </div>
                            <div className={style.detail}>
                                <label className="text-muted">Email</label>
                                <div>{selectedUserDetails.email}</div>
                            </div>
                            <div className={style.detail}>
                                <label className="text-muted">Company</label>
                                <div>{selectedUserDetails.company}</div>
                            </div>

                        </main>
                    </div>}
        </div>
    );
};

export default ContactDetailCard;
