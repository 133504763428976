import { IoCheckmarkDoneOutline, IoCheckmark } from "react-icons/io5";
import { MdOutlineSmsFailed } from "react-icons/md";
import { formatTimestamp } from "../../utils/Utils";
import style from './messageCard.module.css';
import MenuList from "../MenuList/MenuList";
import React, { useState } from "react";
import { FaWpforms } from "react-icons/fa";
const MessageCard = ({
  position,
  type,
  status,
  text,
  date,
  user,
  data,
  onClick,
  href,
  src,
  button,
  list,
}) => {
  const [listOpen, setListOpen] = useState(false);

  const handleClick = () => {
    setListOpen(true);
  };

  return (
    <div
      className={`d-flex ${position === "left" ? "justify-content-start" : "justify-content-end"
        } mb-4  ${style["max-width-lg"]}`}
    >
      <div
        className={`text-white p-2 rounded-3 shadow ${position === "left" ? "bg-light" : `${style.agentCard}`
          }`}
      >
        {/* User name */}
        {user !== "USER" ? (
          <div className={style.agentName}>
            {user === "BOT_REPLY" ? "Bot" : user}
          </div>
        ) : null}

        {/* Content based on message type */}
        {type === "text" && (
          <span className={style.text}>
            {text.split("\n").map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </span>
        )}


        {type === "photo" && (
          <div
            onClick={onClick}
            style={{ cursor: "pointer", maxWidth: "150px", overflow: "hidden" }}
          >
            <img
              src={data.uri}
              alt="Img"
              className="img-fluid rounded"
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "contain",
              }}
            />
            <p className="text-dark mt-2">{text}</p>
          </div>
        )}

        {type === "video" && (
          <div onClick={onClick} style={{ cursor: "pointer" }}>
            <video
              src={data?.videoURL}
              controls
              className="img-fluid rounded"
            />
            <p className="text-dark mt-2">{text}</p>
          </div>
        )}

        {type === "audio" && (
          <div style={{ width: "300px" }}>
            {" "}
            {/* Expand container width if needed */}
            <audio src={data?.audioURL} controls style={{ width: "100%" }} />
          </div>
        )}

        {type === "location" && (
          <div
            onClick={() => window.open({ href }, "_blank")}
            style={{ cursor: "pointer" }}
          >
            <img
              src={src}
              alt="Map"
              className="img-fluid rounded"
              style={{ maxWidth: "150px" }}
            />
            <p className="text-dark mt-2">{text}</p>
          </div>
        )}

        {type === "file" && (
          <div
            onClick={onClick}
            style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
          >
            <img
              src="https://cdn.pixabay.com/photo/2016/03/31/14/48/sheet-1292828_640.png"
              alt="Document"
              style={{
                width: "40px",
                height: "40px",
                objectFit: "contain",
                marginRight: "8px",
              }}
            />
            <span className="text-dark">{text}</span>
          </div>
        )}

        {type === "list" && (
          <div className="d-flex flex-column">
            <span className={style.text}>{text}</span>
            <button className="btn btn-primary mt-2" onClick={handleClick}>
              {button}
            </button>
          </div>
        )}
        {type === "form" && user==="BOT_REPLY"?
        (<div>
          <span className={style.text}>
            {data?.content?.split("\n").map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </span>
          <button className="btn btn-primary mt-2 w-100" >
              Continue
            </button>

        </div>)
        : type === "form" &&(
          <div className="d-flex flex-column justify-content-center ">
            <div className="d-flex w-100 align-items-center text-primary mb-2">
              <FaWpforms/>
              <strong className="ml-2 ">Form Data</strong>

            </div>
            <div className={style.formDetailsCard}>
              <ul className={style.listUnstyled}>
                {Object.entries(data?.content).map(([label, value], index) => (
                  <li key={index} className={style.formDetailsItem}>
                    <strong>{label.replace(/_/g, " ")}: </strong> {value}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}


        {/* Time and checkmarks */}
        <footer className="d-flex justify-content-end align-items-center text-muted mt-1">
          <span className="me-1">{formatTimestamp(date)}</span>
          {status === 'fail' ? (
            <MdOutlineSmsFailed />
          ) : status === 'read' ? (
            <IoCheckmarkDoneOutline />
          ) : status === 'received' ? (
            <IoCheckmarkDoneOutline />
          ) : status === 'sent' ? (
            <IoCheckmark />
          ) : null}
        </footer>
      </div>
      {listOpen && <MenuList list={list} button={button} setListOpen={setListOpen} />}
    </div>
  )
}

export default MessageCard;
